body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes waveAnimation {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 60%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 40%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
    background-color: #ffffff;
  }
  50% {
    transform: scale(1.02);
    background-color: #ffebee;
  }
  100% {
    transform: scale(1);
    background-color: #ffffff;
  }
}

.form-field-accordion-closed {
  animation: heartbeat 1.5s ease-in-out infinite;
}

.form-field-accordion-closed:hover {
  animation: none;
  background-color: #f5f5f5;
}
