:root {
  --background-color: #f2e9e4;
}

html {
  background-color: var(--background-color);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon-container {
  display: inline-block;
  position: relative;
}

.icon {
  transition: transform 0.3s ease-in-out;
}

.valid {
  color: green;
}

.invalid {
  color: red;
}

/* Rotate animation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Applying the animation */
.valid {
  animation: rotate 1s ease-in-out forwards;
}

.invalid {
  animation: rotate -1s ease-in-out forwards;
}

.slider {
  height: auto !important;
}
.slick-slider {
  height: 240px;
}

.slick-slide {
  margin: 0px 20px;
}

.slick-list {
  overflow: visible;
}

.ql-editor {
  min-height: 18em;
  border-radius: 1em;
}

.quill {
  border-radius: 1em;
}

.ql-container {
  border-radius: 1em;
  border: none;
}

.ql-picker-options {
  max-height: 200px;
  overflow-y: scroll;
}
