.bowl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.bowl svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 370px;
}

.shape_fill {
  fill: #ff3a49;
}
